<template>
  <app-modal-copy-business
    v-if="
      business.business_status_id == BUSINESS_STATUS.CREATED ||
      business.business_status_id == BUSINESS_STATUS.AMENDED
    "
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="representative"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.representative.title") }}
      </h1>
      <app-text-tooltip 
           :text="$t('business.structure.copy_from_existing_business')" >
        <app-icon 
          v-if="showCopyBusinessIcon"
          name="ClipboardCopyIcon"
          class="h-9 w-6 text-gray-300 cursor-pointer hover:text-gray-600"
          @click="showModalCopyBusiness = true"
          
        />
      </app-text-tooltip>
    </div>
    <p class="mt-4">
      {{ $t("business.representative.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessRepresentative"
      v-if="business.representative != undefined"
    >
      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.representative.first_name"
          name="business.representative.first_name"
          type="text"
          :placeholder="$t('business.representative.first_name_placeholder')"
          :labelText="$t('business.representative.legal_name')"
          v-model="business.representative.first_name"
          :errorMessage="errors.first_name"
          :disabled="!editMode"
        />
        <app-form-input
          id="business.representative.last_name"
          name="business.representative.last_name"
          type="text"
          :placeholder="$t('business.representative.last_name_placeholder')"
          v-model="business.representative.last_name"
          :errorMessage="errors.last_name"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.representative.email"
          name="business.representative.email"
          type="email"
          :labelText="$t('business.representative.email')"
          v-model="business.representative.email"
          :errorMessage="errors.email"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.representative.designation"
          name="business.representative.designation"
          type="text"
          :placeholder="$t('business.representative.job_position_placeholder')"
          :labelText="$t('business.representative.designation')"
          v-model="business.representative.designation"
          :errorMessage="errors.designation"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-5">
        <!-- <label class="block text-sm font-light text-gray-700">
          {{ $t('business.representative.date_of_birth') }}
        </label>
        <litepie-datepicker class="h-10" as-single :formatter="dobFormatter"
          v-model="business.representative.date_of_birth" :disabled="!editMode" />
        <span v-if="errors.date_of_birth.length > 0" class="text-xs text-error">{{
            errors.date_of_birth[0]
        }}</span> -->
        <app-form-calendar
          class="w-full"
          :labelText="$t('business.representative.date_of_birth')"
          :placeholder="$t('business.representative.date_placeholder')"
          v-model="business.representative.date_of_birth"
          :errorMessage="errors.date_of_birth"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.address_1"
          name="business.representative.address_1"
          type="text"
          :placeholder="$t('business.representative.address_placeholder_1')"
          :labelText="$t('business.representative.home_address')"
          v-model="business.representative.address_1"
          :errorMessage="errors.address_1"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.address_2"
          name="business.representative.address_2"
          type="text"
          :placeholder="$t('business.representative.address_placeholder_2')"
          v-model="business.representative.address_2"
          :errorMessage="errors.address_2"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.postcode"
          name="business.representative.postcode"
          type="number"
          maxlength="5"
          :placeholder="$t('business.representative.postcode_placeholder')"
          v-model="business.representative.postcode"
          :errorMessage="errors.postcode"
          :disabled="!editMode"
          :numberOnly="true"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.city"
          name="business.representative.city"
          type="text"
          onkeydown="return /^[a-z\s]*$/i.test(event.key)"
          :placeholder="$t('business.representative.city_placeholder')"
          v-model="business.representative.city"
          :errorMessage="errors.city"
          :disabled="!editMode"
        />
      </div>

      <app-form-country
        :allowFilter="true"
        :showLabel="false"
        :placeholder="$t('general.country')"
        class="lg:w-7/12 w-full mb-1"
        v-model="business.representative.country"
        :errorMessage="errors.country_id"
        :disabled="!editMode"
      />

      <app-form-state
        :showLabel="false"
        :placeholder="$t('general.address.state')"
        :country_id="business.representative.country?.id ?? 0"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.representative.state"
        :errorMessage="errors.state_id"
        :disabled="!editMode"
      />

      <app-form-telephone-no
        name="business.representative.phone_no"
        :labelText="$t('business.representative.phone_no')"
        class="lg:w-7/12 w-full mb-6"
        v-model="business.representative.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="!editMode"
      />

      <div class="lg:w-7/12 w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="MyKad"
            v-model="business.representative.identification_type"
            :disabled="!editMode"
            checked
            :pattern="'[0-9]{12}$'"
            :required= "true"
          />
          <span class="ml-2">{{ $t("business.representative.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="Passport"
            v-model="business.representative.identification_type"
            :disabled="!editMode"
            :pattern="'[A-Z,a-z,0-9]{8,10}$'"
            :required= "true"
          />
          <span class="ml-2">{{ $t("business.representative.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-10">
        <app-form-input
          id="business.representative.identification"
          name="business.representative.identification"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          :labelText="labelIdentification()"
          v-model="business.representative.identification"
          :errorMessage="errors.identification"
          :disabled="!editMode"
          :numberOnly="ic_type_input.type == 'number'"
        />
      </div>

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.next") }}</app-button
      >
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      ic_type: "MyKad",
      ic_type_input: null,
      emptyErrors: {
        first_name: [],
        last_name:[],
        email: [],
        date_of_birth: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        country_id: [],
        state_id: [],
        phone_no: [],
        identification_type: [],
        identification: [],
        pattern: [],
      },
      dobFormatter: {
        date: "DD/MM/YYYY",
        month: "MMM",
      },
    };
  },
  created() {
    if (this.business.representative?.identification_type) {
      this.ic_type = this.business.representative.identification_type;
    }
  },
  mounted() {
    if (this.business.id == null) {
      this.$router.push({ path: "/business" });
      return;
    }
    this.selectInputType();
  },
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    showCopyBusinessIcon() {
      let statusBusinessAllow = [
        BUSINESS_STATUS.CREATED,
        BUSINESS_STATUS.AMENDED,
      ];

      return (
        this.all_business?.length > 0 &&
        statusBusinessAllow.includes(this.business.business_status_id)
      );
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  watch: {
    ic_type() {
      this.labelIdentification();
    },
  },
  methods: {
    copyBusiness(business) {
    this.business.representative = Object.assign({}, this.business.representative, {
      first_name: business.representative.first_name,
      last_name: business.representative.last_name,
      email: business.representative.email,
      designation: business.representative.designation,
      date_of_birth: business.representative.date_of_birth,
      address_1: business.representative.address_1,
      address_2: business.representative.address_2,
      postcode: business.representative.postcode,
      city: business.representative.city,
      state: business.representative.state,
      phone_no: business.representative.phone_no,
      identification_type: business.representative.identification_type,
      identification: business.representative.identification
  });
},



    onSelected(event) {
      this.ic_type = event.target.value;
      this.business.representative.identification = null;
      this.selectInputType();
    },
    selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: 10,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },
    async submitBusinessRepresentative() {
      this.resetState();

      this.business.representative.country_id =
        this.business.representative.country?.id;
      this.business.representative.state_id =
        this.business.representative.state?.id;

      const businessRepresentative = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessRepresentative",
        this.business.representative
      );

      if (businessRepresentative == null) return;

      this.$router.push({ path: "/business/owners" });
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>

<style scoped>
.form-radio:checked {
  @apply bg-primary;
}
</style>
